<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="1200px"
      top="3%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="content-box">
        <el-form
          ref="ruleForm"
          :model="submitData"
          :rules="submitRules"
          label-width="auto"
          size="small"
        >
          <div class="content">
            <!-- start 基本信息 -->
            <div class="dialog-top">
              <el-row :gutter="rowSpan">
                <el-col :span="rowSpan - 1">
                  <el-form-item label="商品图片">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="onShowPicture('item')"
                      >选择图片</el-button
                    >
                    <div class="image-box">
                      <div
                        class="image-item"
                        v-for="(item, index) in fileList"
                        :key="index"
                      >
                        <el-image
                          :src="item.MediaUrl"
                          :preview-src-list="[item.MediaUrl]"
                          :z-index="999999999999"
                          fit="scale-down"
                          lazy
                        ></el-image>
                        <i
                          class="el-icon-remove remove"
                          @click="fileList.splice(index, 1)"
                        ></i>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="rowSpan / 2">
                  <el-form-item label="商品名称" prop="ItemName">
                    <el-input v-model="submitData.ItemName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="rowSpan / 2">
                  <el-form-item label="商品分类" prop="CategoryId">
                    <el-select v-model="submitData.CategoryId" filterable>
                      <el-option
                        v-for="item in categorySelector"
                        :key="item.CategoryId"
                        :label="`${item.Prefix + item.Name}`"
                        :value="item.CategoryId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="rowSpan / 2">
                  <el-form-item label="可用门店" required>
                    <el-select
                      v-model="submitData.ValidStoreList"
                      multiple
                      clearable
                      @change="onChangeStores"
                    >
                      <el-option
                        v-for="item in storesList"
                        :key="item.StoresGuid"
                        :label="item.StoresName"
                        :value="item.StoresGuid"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="rowSpan / 2">
                  <el-form-item label="商品卖点">
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="submitData.SellingPoint"
                      placeholder="选填，商品卖品简述，例如：此款商品美观大方 性价比高 不容错过"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <!-- end 基本信息 -->

            <!-- start 其他信息 -->
            <div class="dialog-center">
              <div class="tabs">
                <div
                  v-for="(item, index) in tabsData"
                  :key="'tabs' + index"
                  :class="activeIndex == index ? 'action' : ''"
                  @click="onChangeTabs(index)"
                >
                  {{ item }}
                </div>
              </div>
              <div class="tabs-chile" v-show="activeIndex == 0">
                <el-row :gutter="rowSpan">
                  <el-col :span="rowSpan">
                    <el-form-item label="商品规格" required>
                      <el-radio-group v-model="submitData.AttrMultiple">
                        <el-radio
                          v-for="norms in [
                            { Name: '单规格', Value: 0 },
                            { Name: '多规格', Value: 1 },
                          ]"
                          :key="norms.Value"
                          :label="norms.Value"
                        >
                          {{ norms.Name }}
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>

                <div v-show="submitData.AttrMultiple === 0">
                  <el-row :gutter="rowSpan">
                    <el-col :span="rowSpan / 3">
                      <el-form-item label="商品编码" size="medium">
                        <el-input
                          v-model="tempOnlySkuList[0].ItemCode"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="rowSpan / 3">
                      <el-form-item label="商品价格" size="medium" required>
                        <el-input
                          v-model="tempOnlySkuList[0].ItemPrice"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="rowSpan / 3">
                      <el-form-item label="商品划线价" size="medium" required>
                        <el-input
                          v-model="tempOnlySkuList[0].LinePrice"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="rowSpan / 3">
                      <el-form-item label="当库存数量" size="medium" required>
                        <el-input
                          v-model="tempOnlySkuList[0].StockCount"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>

                <div v-show="submitData.AttrMultiple === 1">
                  <el-form-item label="">
                    <!-- start 规格添加 -->
                    <div class="norms-box">
                      <div
                        class="norms-item"
                        v-for="(keyItem, keyIndex) in submitData.AttrList"
                        :key="keyItem.AttrKey.KeyId"
                      >
                        <el-tag
                          class="key-name"
                          type="primary"
                          size="default"
                          effect="dark"
                          closable
                          @close="onDeleteAttr(submitData.AttrList, keyIndex)"
                          >{{ keyItem.AttrKey.KeyName }}</el-tag
                        >
                        <div class="value-box">
                          <div
                            class="value-item"
                            v-for="(valueItem,
                            valueIndex) in keyItem.AttrValues"
                            :key="valueItem.ValueId"
                          >
                            <el-tag
                              type="primary"
                              size="default"
                              effect="plain"
                              closable
                              @close="
                                onDeleteAttr(keyItem.AttrValues, valueIndex)
                              "
                              >{{ valueItem.ValueName }}</el-tag
                            >
                          </div>

                          <div class="add-input">
                            <el-input
                              v-model="valuesArr[keyIndex]"
                              size="small"
                            >
                              <el-button
                                slot="append"
                                type="info"
                                @click="
                                  onInserValue(
                                    keyIndex,
                                    keyItem.AttrValues.length,
                                    valuesArr[keyIndex]
                                  )
                                "
                                >添加</el-button
                              >
                            </el-input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="add-norms">
                      <template v-if="!isAddNorms">
                        <el-button
                          type="primary"
                          size="mini"
                          plain
                          @click="isAddNorms = true"
                          >添加规格</el-button
                        >
                      </template>
                      <template v-else>
                        <el-form-item label="规格名称" size="small" required>
                          <el-input
                            v-model="normsName"
                            placeholder="请输入规格名称"
                            size="medium"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="规格值" size="small" required>
                          <el-input
                            v-model="normsValue"
                            placeholder="请输入规格名称"
                            size="medium"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label=" " size="small">
                          <el-button
                            type="primary"
                            size="mini"
                            @click="onAddNorms"
                            >确定</el-button
                          >
                          <el-button
                            type="info"
                            size="mini"
                            @click="isAddNorms = false"
                            >取消</el-button
                          >
                        </el-form-item>
                      </template>
                    </div>
                    <!-- end 规格添加 -->

                    <el-divider />

                    <!-- start 规格参数设置 -->
                    <el-form-item label="批量设置" size="medium">
                      <div class="batch-setting">
                        <el-input
                          v-model="batchSetting.itemCode"
                          placeholder="商家编码"
                          size="small"
                          clearable
                        ></el-input>
                        <el-input
                          v-model="batchSetting.itemPrice"
                          placeholder="销售价"
                          size="small"
                          clearable
                        ></el-input>
                        <el-input
                          v-model="batchSetting.linePrice"
                          placeholder="划线价"
                          size="small"
                          clearable
                        ></el-input>
                        <el-input
                          v-model="batchSetting.stockCount"
                          placeholder="库存数量"
                          size="small"
                          clearable
                        ></el-input>
                        <el-button
                          type="info"
                          size="small"
                          plain
                          @click="onBatchExecute"
                          >确定</el-button
                        >
                      </div>
                    </el-form-item>
                    <!-- end 规格参数设置 -->

                    <el-table
                      class="norms-table"
                      stripe
                      :data="tempMoreSkuList"
                      size="mini"
                      v-loading="loading"
                    >
                      <el-table-column
                        v-if="attrItem.AttrValues.length"
                        v-for="(attrItem, attrIndex) in submitData.AttrList"
                        :key="attrIndex"
                        :label="attrItem.AttrKey.KeyName"
                        align="center"
                        min-width="120"
                      >
                        <template slot-scope="scope">
                          {{ skuKeyList[scope.$index][attrIndex] }}
                        </template>
                      </el-table-column>

                      <el-table-column
                        label="规格照片"
                        align="center"
                        min-width="110"
                      >
                        <template slot-scope="scope">
                          <div
                            class="attr-image"
                            @click="onShowPicture('attr', scope.$index)"
                          >
                            <el-upload
                              v-if="!scope.row.ItemImage"
                              list-type="picture-card"
                              :auto-upload="false"
                              :disabled="true"
                            >
                              <i class="el-icon-plus"></i>
                            </el-upload>
                            <div v-else class="image-item">
                              <el-image
                                :src="scope.row.ItemImage"
                                fit="scale-down"
                                :lazy="true"
                              ></el-image>
                              <i
                                class="el-icon-remove remove"
                                @click.stop="scope.row.ItemImage = ''"
                              ></i>
                            </div>
                          </div>
                        </template>
                      </el-table-column>

                      <el-table-column
                        label="商家编码"
                        align="center"
                        min-width="140"
                      >
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.ItemCode"></el-input>
                        </template>
                      </el-table-column>

                      <el-table-column
                        label="销售价"
                        align="center"
                        min-width="140"
                      >
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.ItemPrice"></el-input>
                        </template>
                      </el-table-column>

                      <el-table-column
                        label="划线价"
                        align="center"
                        min-width="140"
                      >
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.LinePrice"></el-input>
                        </template>
                      </el-table-column>

                      <el-table-column
                        label="库存"
                        align="center"
                        min-width="140"
                      >
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.StockCount"></el-input>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-form-item>
                </div>
              </div>

              <div class="tabs-chile" v-show="activeIndex == 1">
                <el-form-item label="商品详情" required>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="onShowQuilEditor"
                    >编辑</el-button
                  >
                </el-form-item>
              </div>

              <div class="tabs-chile" v-show="activeIndex == 2">
                <el-row :gutter="rowSpan">
                  <el-col :span="rowSpan">
                    <el-form-item label="是否参与拼团" required>
                      <el-radio-group
                        v-model="submitData.GrouponUse"
                        @change="submitData.GrouponUse = $event"
                      >
                        <el-radio
                          v-for="item in [
                            { Name: '不拼团', Value: 0 },
                            { Name: '仅拼团', Value: 1 },
                            { Name: '不限', Value: 2 },
                          ]"
                          :key="item.Value"
                          :label="item.Value"
                        >
                          {{ item.Name }}
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="rowSpan">
                    <el-form-item label="商品状态" required>
                      <el-radio-group
                        v-model="submitData.OnSale"
                        @change="submitData.OnSale = $event"
                      >
                        <el-radio
                          v-for="item in [
                            { Name: '上架', Value: 1 },
                            { Name: '下架', Value: 0 },
                          ]"
                          :key="item.Value"
                          :label="item.Value"
                        >
                          {{ item.Name }}
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="rowSpan / 3">
                    <el-form-item label="商品排序">
                      <el-input v-model="submitData.Sort"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <!-- end 其他信息 -->
          </div>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="saveItem">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 图片库 -->
    <DFGalleryPictrue
      ref="DFGalleryPictrue"
      @onComfirm="onSelectPicture"
    ></DFGalleryPictrue>

    <!-- 文本编辑器 -->
    <DfQuilEditorDrawer
      ref="DfQuilEditorDrawer"
      title="编辑商品详情"
      @onBlurText="submitData.Detail = $event"
    ></DfQuilEditorDrawer>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";
import Common from "@/api/common.js";

export default {
  data() {
    return {
      title: "", // 对话框标题
      rowSpan: 24,
      colSpan: 12,
      activeIndex: 0, // tabs激活下标
      pictureType: "", // 打开图片库的类型 attr 规格 item 商品
      attrImageIndex: 0, // 商品规格的图片下标
      normsName: "", // 规格名
      normsValue: "", // 规格值
      valuesArr: [], // 规格值输入框的缓存值
      batchSetting: {
        // 批量设置
        itemCode: "",
        itemPrice: "",
        linePrice: "",
        stockCount: "",
      },
      submitData: {
        ItemId: 0,
        ItemName: "",
        CategoryId: 0,
        AttrMultiple: 0,
        IsGroupon: 0,
        GrouponOnly: 0,
        GalleryImageList: [],
        Detail: "",
        Sort: 0,
        OnSale: 0,
        AttrList: [],
        SkuList: [],
      },
      submitRules: {
        // 检验规则
        ItemName: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        CategoryId: [
          { required: true, message: "请输入商品名称", trigger: "change" },
        ],
      },
      tempOnlySkuList: [
        // 单规格的临时skulit
        {
          SkuId: 0,
          AttrValueList: [],
          AttrValueKey: "",
          ItemCode: "",
          ItemImage: "",
          LinePrice: 0,
          ItemPrice: 0,
          GrouponPrice: 0,
          StockCount: 0,
        },
      ],
      tempMoreSkuList: [], // 多规格的临时skulit
      storesList: [], // 库存列表
      skuKeyList: [], // 递归attrKey的缓存列表，只用于渲染表格
      tabsData: ["规格/库存", "商品详情", "其他设置"], // tabs数据
      categorySelector: [], // 商品分类列表
      fileList: [], // 商品图片列表
      loading: false, // 表格加载
      showDialog: false, // 显示对话框
      isAddNorms: false, // 展开添加规格输入框
    };
  },

  mounted() {},

  methods: {
    // 初始化商品信息
    async initItem() {
      try {
        let { data } = await Shop.initItem({
          item_id: this.submitData.ItemId,
        });
        this.categorySelector = data.categorySelector;
        if (data.editor == null) {
          this.resetData();
          // console.log(this.submitData);
          return;
        } else if (data.editor.AttrMultiple) {
          this.tempMoreSkuList = JSON.parse(
            JSON.stringify(data.editor.SkuList)
          );
        } else if (!data.editor.AttrMultiple) {
          this.tempOnlySkuList = JSON.parse(
            JSON.stringify(data.editor.SkuList)
          );
        }
        data.editor.CategoryId = this.categorySelector[0].CategoryId;
        this.submitData = await data.editor;
        this.fileList = await data.editor.GalleryImageList.map((item) => {
          return {
            MediaUrl: item,
          };
        });
        await this.skuListHandle();
        // console.log(this.tempOnlySkuList);
      } catch (err) {
        console.log(err);
      }
    },

    // 获取门店列表
    async getStoreList() {
      try {
        let { data } = await Common.getStoreList();
        this.storesList = [{ StoresGuid: "", StoresName: "全部" }, ...data];
      } catch (err) {
        console.log(err);
      }
    },

    // 保存商品信息
    saveItem() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          let { tempOnlySkuList, tempMoreSkuList } = this;
          let submitData = JSON.parse(JSON.stringify(this.submitData));
          submitData.GalleryImageList = this.fileList.map(
            (item) => item.MediaUrl
          );
          submitData.SkuList = submitData.AttrMultiple
            ? tempMoreSkuList
            : tempOnlySkuList;
          if (!submitData.AttrMultiple) {
            if (!submitData.SkuList[0].ItemPrice) {
              return this.$message.error("请输入商品价格");
            } else if (!submitData.SkuList[0].LinePrice) {
              return this.$message.error("请输入商品划线价");
            } else if (!submitData.SkuList[0].StockCount) {
              return this.$message.error("请输入库存数量");
            }
          } else if (!submitData.Detail) {
            return this.$message.error("请输入商品详情");
          } else if (!submitData.ValidStoreList.length) {
            return this.$message.error("请选择可用门店");
          }
          // console.log(submitData.AttrMultiple, tempMoreSkuList);
          try {
            let { errcode } = await Shop.saveItem(submitData);
            if (errcode == 0) {
              this.$message.success("保存成功");
              this.$emit("success");
              this.onCloseDialog();
            }
          } catch (err) {
            console.log(err);
          }
        }
      });
    },

    // 添加规格名
    async addAttrKey(name) {
      try {
        let { data, errcode } = await Shop.addAttrKey({
          name,
        });
        if (errcode == 0) {
          this.submitData.AttrList.push({
            AttrKey: {
              KeyId: data.KeyId,
              KeyName: name,
            },
            AttrValues: [],
          });
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 添加规格值
    async addAttrValue(keyIndex, valueIndex, name) {
      let attrList = JSON.parse(JSON.stringify(this.submitData.AttrList)),
        skuList = JSON.parse(JSON.stringify(this.tempMoreSkuList));
      try {
        let { data, errcode } = await Shop.addAttrValue({
          name,
        });
        if (errcode == 0) {
          // console.log(this.submitData.AttrList, keyIndex, valueIndex);
          attrList[keyIndex].AttrValues[valueIndex] = {
            ValueId: data.ValueId,
            ValueName: name,
          };
          // skuList[keyIndex].AttrValueList.push(data.ValueId);
          // this.tempMoreSkuList = attrList.map((item) => {
          //   return item.AttrValues.map((e) => e.ValueId);
          // });
          this.submitData.AttrList = attrList;
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 根据AttrList计算成SkuList
    async skuListHandle() {
      let that = this;
      let attrList = JSON.parse(JSON.stringify(this.submitData.AttrList)),
        skuList = [],
        skuKeyList = [];

      let attrValueArr = [],
        attrKeyArr = [];

      this.loading = await true;
      /* 处理数据源使它成为一个二维数组，如：
      [
        {AttrKey: {...}, AttrValues: [{ValueId:108, ...},{ValueId:109, ...}]},
        {AttrKey: {...}, AttrValues: [{ValueId:110, ...},{ValueId:111, ...},{ValueId:112, ...}]},
        ...
      ]

      转换成：

      [
        [108,109],
        [110,111,112],
        ...
      ] */
      // let attrArr = await attrList.map((item) => {
      //   return item.AttrValues.map((e) => e.ValueId);
      // });
      await attrList.map((keyItem, keyIndex) => {
        if (keyItem.AttrValues.length > 0) {
          attrValueArr[keyIndex] = keyItem.AttrValues.map((e) => e.ValueId);
          attrKeyArr[keyIndex] = keyItem.AttrValues.map((e) => e.ValueName);
        }
      });

      /* 递归排列算法
       *
       * 原理就是当前索引一直循环至二维数组，然后递归调用自身，直到索引等于二维数组的长度，此时当前数组便是一种排列结果
       * 以此类推，直到所有排列结果都计算出来，最后根据需求将排列结果添加到SkuList，网上的递归算法大多不能产生我们想要的
       * 我们要的是——递归排列结果，而不是扁平化数组或递归加减数额，更不是那种复杂的树形结构，而是要获取每次排列完的结果
       *
       * @param {Number} index 当前遍历的索引
       * @param {Array, Array} values, keys 当前排列的数组 values：规格ID数组 keys：规格名称数组
       */
      const combine = async (index, { values = [], keys = [] }) => {
        if (index === attrValueArr.length) {
          let valueList = values.slice(),
            keyList = keys.slice();

          let param = {
            AttrValueList: valueList,
            AttrValueKey: `_${valueList.join("_")}_`,
          };

          for (let i = 0; i < that.tempMoreSkuList.length; i++) {
            if (that.tempMoreSkuList[i].AttrValueKey == param.AttrValueKey) {
              param = await that.tempMoreSkuList[i];
              break;
            } else if (i == that.tempMoreSkuList.length - 1) {
              param = await {
                ...param,
                SkuId: 0,
                ItemCode: "",
                ItemImage: "",
                LinePrice: 0,
                ItemPrice: 0,
                GrouponPrice: 0,
                StockCount: 0,
              };
            }
          }

          await skuList.push(param);
          await skuKeyList.push(keyList);
          return;
        }

        const valueArr = attrValueArr[index],
          keyArr = attrKeyArr[index];
        for (let i = 0; i < valueArr.length; i++) {
          await values.push(valueArr[i]);
          await keys.push(keyArr[i]);
          await combine(index + 1, { values, keys });
          await values.pop();
          await keys.pop();
        }
      };

      await combine(0, { values: [], keys: [] });
      this.tempMoreSkuList = await skuList;
      this.skuKeyList = await skuKeyList;
      this.loading = await false;
      // await console.log(skuList, skuKeyList);
    },

    // 添加规格按钮点击事件
    async onAddNorms() {
      if (!this.normsName) {
        return this.$message.warning("请输入规格名称");
      } else if (!this.normsValue) {
        return this.$message.warning("请输入规格值");
      }

      await this.addAttrKey(this.normsName);
      await this.addAttrValue(
        this.submitData.AttrList.length
          ? this.submitData.AttrList.length - 1
          : 0,
        0,
        this.normsValue
      );

      await this.skuListHandle();
      this.normsName = "";
      this.normsValue = "";
      this.isAddNorms = false;
    },

    // 单独添加规格值
    async onInserValue(keyIndex) {
      let valuesArr = JSON.parse(JSON.stringify(this.valuesArr));
      if (!valuesArr[keyIndex]) {
        return this.$message.warning("请输入规格值");
      }

      await this.addAttrValue(
        keyIndex,
        this.submitData.AttrList[keyIndex].AttrValues.length,
        valuesArr[keyIndex]
      );

      await this.skuListHandle();
      valuesArr[keyIndex] = await "";
      this.valuesArr = valuesArr;
    },

    // 删除商品规格
    onDeleteAttr(item, keyIndex) {
      item.splice(keyIndex, 1);
      // console.log(this.submitData.AttrList);
      if (!this.submitData.AttrList.length) {
        return (this.tempMoreSkuList = []);
      }
      this.skuListHandle();
      // console.log(this.tempMoreSkuList);
    },

    // 批量设置
    onBatchExecute() {
      let { batchSetting } = this;
      let skuList = JSON.parse(JSON.stringify(this.tempMoreSkuList));

      // console.log(batchSetting);
      skuList.forEach((item) => {
        item.ItemCode = batchSetting.itemCode;
        item.ItemPrice = Number(batchSetting.itemPrice);
        item.LinePrice = Number(batchSetting.linePrice);
        item.StockCount = Number(batchSetting.stockCount);
      });

      this.tempMoreSkuList = skuList;
    },

    // 打开文本编辑器抽屉
    onShowQuilEditor() {
      this.$refs.DfQuilEditorDrawer.onShowDrawer(this.submitData.Detail);
    },

    // 打开图片库对话框
    onShowPicture(type, index = 0) {
      this.pictureType = type;
      this.attrImageIndex = index;
      this.$refs.DFGalleryPictrue.onShowDialog();
    },

    // 选中图片返回事件
    onSelectPicture(event) {
      if (this.pictureType == "attr") {
        let tempMoreSkuList = JSON.parse(JSON.stringify(this.tempMoreSkuList));
        tempMoreSkuList[this.attrImageIndex].ItemImage = event[0].MediaUrl;
        this.tempMoreSkuList = tempMoreSkuList;
      } else {
        this.fileList = [...this.fileList, ...event];
      }
    },

    // 改变门店选中事件
    onChangeStores(event) {
      let res = event.filter((item) => item == "");
      if (res.length > 0) {
        this.submitData.ValidStoreList = [];
        this.storesList.forEach((e) => {
          this.submitData.ValidStoreList.push(e.StoresGuid);
        });
        this.submitData.ValidStoreList.shift();
      }
    },

    // tabs改变事件
    onChangeTabs(index) {
      this.activeIndex = index;
      console.log(index);
    },

    // 重置数据
    resetData() {
      this.submitData = {
        ItemId: 0,
        ItemName: "",
        CategoryId: 0,
        AttrMultiple: 0,
        IsGroupon: 0,
        GrouponOnly: 0,
        GalleryImageList: [],
        Detail: "",
        Sort: 0,
        OnSale: 0,
        AttrList: [],
        SkuList: [],
      };
      this.tempOnlySkuList = [
        {
          SkuId: 0,
          AttrValueList: [],
          AttrValueKey: "",
          ItemCode: "",
          ItemImage: "",
          LinePrice: 0,
          ItemPrice: 0,
          GrouponPrice: 0,
          StockCount: 0,
        },
      ];
      this.fileList = [];
      this.activeIndex = 0;
      this.tempMoreSkuList = [];
      this.submitData.CategoryId = this.categorySelector[0].CategoryId;
      this.batchSetting = {
        itemCode: "",
        itemPrice: "",
        linePrice: "",
        stockCount: "",
      };
      this.$refs["ruleForm"].resetFields();
    },

    // 打开对话框
    onShowDialog(event = "") {
      this.title = event ? "编辑商品" : "添加商品";
      this.submitData = JSON.parse(JSON.stringify(event || { ItemId: 0 }));
      this.initItem();
      this.getStoreList();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  width: auto;
  max-height: 70vh;
  box-sizing: border-box;
  overflow-y: auto;

  .el-row {
    width: 100%;
    box-sizing: border-box;

    .el-input,
    .el-select {
      width: 100%;
    }
  }

  .dialog-top {
    width: 100%;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    padding: 20px 10px;

    .image-box {
      .flex-row;
      align-items: unset;
      flex-wrap: wrap;
      width: 100%;
      //   max-height: 300px;
      //   overflow-y: auto;

      .image-item {
        position: relative;
        height: 90px;
        width: 90px;
        padding: 5px;
        margin: 5px 0;
        margin-right: 10px;
        border-radius: 5px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;

        .el-image {
          height: 100%;
          width: 100%;
        }
        &:hover {
          .remove {
            visibility: unset;
          }
        }
      }
    }
  }

  .dialog-center {
    width: 100%;
    margin-top: 20px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    .tabs {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 20px;
      border-bottom: 1px solid #dcdfe6;
      box-sizing: border-box;
      margin-bottom: 20px;
      > div {
        display: flex;
        align-items: center;
        height: 40px;
        margin-right: 40px;
        box-sizing: border-box;
        border-bottom: 2px solid transparent;
        transition: all 0.5s;
      }
      .action {
        border-bottom: 2px solid #409eff;
      }
    }
    .tabs-chile {
      padding: 0 10px;

      .norms-box {
        .el-tag,
        .add-input .el-input {
          margin-right: 10px;
          margin-bottom: 10px;
        }
        .value-box {
          .flex-row;
          flex-wrap: wrap;

          .add-input {
            width: 160px;
          }
        }
      }

      .add-norms {
        .el-input {
          width: 200px;
        }
      }

      .batch-setting {
        .flex-row;
        flex-wrap: wrap;
        margin-bottom: 10px;

        .el-input {
          width: 140px;
          margin-right: 10px;
        }
      }

      .norms-table {
        ::v-deep .cell {
          overflow: unset;
        }
        ::v-deep .attr-image {
          .flex-row;
          justify-content: center;
          margin: 0;
          box-sizing: border-box;

          > div,
          .el-upload,
          .el-image {
            width: 50px;
            height: 50px;
            line-height: 0;
          }

          > div {
            .el-upload {
              position: relative;

              i {
                position: absolute;
                font-size: 20px;
                top: calc(50% - (20px / 2));
                left: calc(50% - (20px / 2));
              }
            }
          }

          .image-item {
            position: relative;

            &:hover {
              .remove {
                visibility: unset;
              }
            }
          }
        }

        .el-image {
          border-radius: 5px;
          background: white;
          border: 1px solid #ccc;
        }
      }
    }
  }

  .remove {
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 22px;
    color: #f56c6c;
    visibility: hidden;
    cursor: pointer;
  }
}
</style>
